import React, { useContext, useEffect } from 'react'

import { Spring } from 'react-spring/renderprops'
import { TransitionState } from 'gatsby-plugin-transition-link'
import SEO from "components/globals/seo"
import { MainContext } from 'context/context'
import 'core-js'

export default ({
    children,
    title,
    description,
    image,
    noIndex
}) => {
    const {
        initNewPage,
        setAcknowledgementVisible
    } = useContext(MainContext)

    useEffect(() => {
        if(typeof window !== 'undefined') {
            setAcknowledgementVisible(!window.location.href.includes('yc-labs'))
        }

        setTimeout(() => {
            initNewPage()
        },500)
    }, [])

    return (
        <>
            <SEO 
                title={title} 
                description={description} 
                image={image}
                noIndex={noIndex}
            />
            <TransitionState>
                {({ transitionStatus, exit, entry }) => {
                    const mount = ['entering', 'entered'].includes(transitionStatus)
                    const seconds = mount ? entry.length : exit.length

                    return (
                        <Spring
                            to={{
                                transform: `translateY(${mount ? 0 : '-20px'})`,
                                opacity: mount ? 1 : 0,
                            }}
                            config={{
                                duration: seconds * 1000,
                            }}
                            delay={mount ? 300 : 0}
                        >
                            {props => {
                                return (
                                    <div style={{
                                        ...props,
                                        backgroundColor: 'white'
                                    }}>
                                        { children }
                                    </div>
                                )
                            }}
                        </Spring>
                    )
                }}
            </TransitionState>
        </>
    )
}