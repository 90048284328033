import React, { useEffect, useContext, useRef } from "react"
import Button from "components/globals/button"
import { MainContext } from 'context/context'
import Link from 'components/globals/link'
import Ticker from 'react-ticker'
import { times } from 'lodash'

import LabsLogo from 'images/labs-logo.png'

import { useLocation } from "@reach/router"

import { useIsMobile } from 'utils'

export default ({
	copy,
	largeCopy,
	links,
	backgroundVideo,
	backgroundImage,
	staffFeature,
	staffFeatureMobileImage,
	showLabsTitle,
	fullSizeBackgroundVideo,
	backgroundColor,
	button,
	subCopy,
	preCopy,
	alignment,
	dividerImage,
	isFirst,
}) => {
	const {
		addInvertedHeaderSection,
		setOpenMenuType
	} = useContext(MainContext)

	const elementRef = useRef(null)

	const location = useLocation()
	const isMobile = useIsMobile()

	useEffect(() => {
		if (elementRef && elementRef.current) {
			addInvertedHeaderSection(elementRef.current)

			elementRef.current.querySelectorAll('.sub-copy a').forEach(a => {
				a.addEventListener('click', e => {
					const href = a.getAttribute('href')

					if (~href.indexOf('openContact')) {
						e.preventDefault()

						setTimeout(() => {
							setOpenMenuType('contact')
						}, 100)
					}
				})
			})

			if (isFirst) {
				setTimeout(() => {
					const cursorWrapper = document.getElementById('cursor-wrapper')
					cursorWrapper.classList.add('inverted')
				}, 300)
			}
		}
	}, [elementRef])

	const _copy = (() => {
		const __copy = copy || ''

		if (!links || !links.length) {
			return [__copy.replace(
				"Your Creative",
				'<span class="yc">Your Creative</span>'
			)]
		} else {
			let response = __copy

			links.forEach(link => {
				const { code, logo } = link

				if (code && logo) {
					response = response.replace(
						code,
						`<span class="link ${code.replace("[", "").replace("]", "").toLowerCase()}"><img src="${logo.mediaItemUrl}" /></span>`
					)
				}
			})

			const lines = response.split(/\n/)

			return lines
		}
	})().filter(Boolean)

	const isServices = location && ~location.pathname.indexOf('/services/')

	const h1Inner = (
		<span>
			{_copy.map((line, index) => {
				const link = links ? links[index] : null

				if (links && links.length && !link) {
					return null
				}

				if (link && !link.project) {
					return null
				}

				return (
					<React.Fragment key={index}>
						{ link ?
							<Link
								to={link ? link.project.link : '/'}
								className={`has-cursor-effect`}
							>
								<div className={'hover-section'}>
									<div>
										{link.hoverGraphic ?
											<img alt={''} src={link.hoverGraphic.mediaItemUrl} />
											: null}
									</div>
								</div>
								<span
									dangerouslySetInnerHTML={{ __html: line }}
									onMouseMove={e => {
										const { pageX, pageY } = e

										const link = e.currentTarget.parentElement
										const { x, y } = link.getBoundingClientRect()

										const xOffset = pageX - x
										const yOffset = pageY - y

										const hoverSection = e.currentTarget.previousElementSibling

										hoverSection.style.transform = `translate(${xOffset}px, ${yOffset}px)`
									}}
									onMouseEnter={e => {
										e.currentTarget.previousElementSibling.classList.add('visible')
									}}
									onMouseLeave={e => {
										e.currentTarget.previousElementSibling.classList.remove('visible')
									}}
								/>
							</Link>
							:
							<span dangerouslySetInnerHTML={{ __html: line }} />
						}
					</React.Fragment>
				)
			})}
		</span>
	)

	const videoIfExists = backgroundVideo ? (
		<video muted loop autoPlay playsInline>
			<source src={backgroundVideo.mediaItemUrl} type="video/mp4" />
		</video>
	) : null

	const labsTitleContent = showLabsTitle ? times(7, index => {
		return (
			<li key={index}>
				<img alt={`YC (Your Creative) logo`} src={LabsLogo} />
				<span>
					Labs
				</span>
			</li>
		)
	}) : null

	const bgImage = ( staffFeature && staffFeatureMobileImage && isMobile ) ? staffFeatureMobileImage.mediaItemUrl : backgroundImage ? backgroundImage.mediaItemUrl : ''

	return (
		<>
			<div
				className={`container deep ${(!fullSizeBackgroundVideo && (backgroundVideo || backgroundImage)) ? 'has-video inverted-cursor' : ''} background-${backgroundColor} ${backgroundColor !== 'white' ? 'inverted-cursor' : ''} ${alignment} ${dividerImage ? 'has-divider' : ''} ${staffFeature ? 'staffFeature' : ''} ${ (links?.length && !isMobile) ? 'centre' : ''} ${largeCopy ? 'largeCopy' : ''}`}
				ref={backgroundColor === 'black' ? ref => elementRef.current = ref : null}
			>
				{fullSizeBackgroundVideo ?
					videoIfExists || bgImage ? <img data-scroll data-scroll-speed={-2} className={'full-size-bg'} src={bgImage} /> : null
					: null}
				<div>
					{ fullSizeBackgroundVideo ? null : bgImage ?
						<img data-scroll data-scroll-speed={-1} src={bgImage} className={'bg'} />
					: null}
					<div className="inner">
						{isServices ?
							<Link className={'back-link has-cursor-effect'} to={`/services`}>
								<i className={'fe fe-arrow-left'} />
								<span>
									Back to Services
								</span>
							</Link>
							: null}
						{preCopy &&
							<p className={'pre-copy'}>
								<span dangerouslySetInnerHTML={{ __html: preCopy }} />
							</p>
						}
						{showLabsTitle ?
							isMobile ?
								<Ticker>
									{() => {
										return labsTitleContent
									}}
								</Ticker>
								:
								<ul
									className={'labs-logo-ticker'}
									data-scroll
									data-scroll-speed={2}
									data-scroll-direction="horizontal"
								>
									{labsTitleContent}
								</ul>
							: null}
						{isFirst ?
							<h1 className={(links && links.length) ? 'has-links' : ''}>
								{h1Inner}
							</h1>
							:
							<div className={`h1 ${(links && links.length) ? 'has-links' : ''}`}>
								{h1Inner}
							</div>
						}
						{subCopy &&
							<div
								className="sub-copy big"
							>
								<span dangerouslySetInnerHTML={{ __html: subCopy.replace(/a href/g, 'a class="has-cursor-effect" href') }} />
							</div>
						}
						{button &&
							<div>
								<div className={'button-container'}>
									<Button
										label={button.title}
										url={button.url}
										icon={'arrow-right'}
										iconRight
										inverted={backgroundColor === 'white' && ( !backgroundVideo && !backgroundImage)}
									/>
								</div>
							</div>
						}
					</div>
					{(backgroundVideo || backgroundImage) && !fullSizeBackgroundVideo && videoIfExists}
				</div>
			</div>
			{dividerImage &&
				<div className={'divider'}>
					<img
						src={dividerImage.mediaItemUrl}
						data-scroll
						data-scroll-speed="-1"
						alt={''}
					/>
				</div>
			}
		</>
	)
}
